import React from "react";
import Layout from "../components/layout/MainLayout";
import { Container, Row } from "react-bootstrap";
import { graphql } from "gatsby";
import Sidebar from "../components/routes/Home/News/Sidebar/Sidebar";
import MainColumn from "../components/routes/Home/News/MainColumns/MainColumn";
import { Crumbs } from "../components/common/ntmwd/elements/Crumbs";
import { SEO } from "wmk-lib";
import { subdomainUrl, siteTitle, twitter } from "../vars/helpers";

const IndexPage = ({ data }) => {
  const feed = data.feed.edges.map((e) => e.node);
  const news = data.news.edges.map((e) => e.node);

  return (
    <Layout>
      <SEO
        baseUrl={subdomainUrl}
        title={"Newsroom"}
        siteTitle={siteTitle}
        slug="/"
        twitterHandle={twitter}
        twitterImage={null}
        ogImage={null}
      />
      <Container>
        <Row>
          <Crumbs
            breadcrumbs={[
              { title: "Home", to: "https://ntmwd.com/", target: "self" },
              { title: "News Room", to: "/", target: undefined }
            ]}
          />
        </Row>
        <Row>
          <MainColumn
            sections={[
              {
                title: "News & Highlights",
                posts: feed.slice(0, 5),
                tag: { slug: "news-feed", title: "News Feed" },
                tags: [{ slug: "news-feed", title: "News Feed" }]
              },
              {
                title: "NTMWD In The News",
                posts: news.slice(0, 5),
                tag: { slug: "in-the-news", title: "In The News" },
                tags: [{ slug: "in-the-news", title: "In The News" }]
              }
            ]}
          />
          <Sidebar />
        </Row>
      </Container>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    feed: allWpPost(
      filter: { tags: { nodes: { elemMatch: { slug: { eq: "news-feed" } } } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...NodePostFields
        }
      }
    }
    news: allWpPost(
      filter: {
        tags: { nodes: { elemMatch: { slug: { in: ["in-the-news"] } } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...NodePostFields
        }
      }
    }
  }
`;
